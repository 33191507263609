<template>
    <div class="affiliate-link-list-section">
        <div v-for="link in affiliateLinksInfo" :key="link.name" class="affiliate-link-row">
            <p class="link-title">
                {{ $t('publicAffiliate.affiliateLink') }}
            </p>

            <DsListItem
                :title="link.name"
            >
                <template #description>
                    <span>{{ linkUrl(link.trackingLink) }}</span>

                    <DsButtonGroup class="action-section">
                        <DsFilledButton @click="copyAffiliateLink(link.trackingLink)">
                            {{ $t('publicAffiliate.copyLink') }}
                        </DsFilledButton>

                        <DsOutlineButton @click="openLinkUrl(link.trackingLink)">
                            {{ $t('publicAffiliate.openInTab') }}
                        </DsOutlineButton>
                    </DsButtonGroup>
                </template>
            </DsListItem>
        </div>

        <div v-if="affiliateLinksInfo.length===0" class="no-affiliate-link-record" data-qa="no-links">
            {{ $t('publicAffiliate.noLinkAssigned') }}
        </div>
    </div>
</template>

<script>
import {
    DsListItem,
    DsButtonGroup,
    DsFilledButton,
    DsOutlineButton,
} from '@infusionsoft/design-system';

export default {
    components: {
        DsListItem,
        DsButtonGroup,
        DsFilledButton,
        DsOutlineButton,
    },

    props: {
        affiliateLinksInfo: {
            type: Array,
            default: () => ([]),
        },
    },

    methods: {
        linkUrl(url) {
            if (url.length > 30) {
                return url.substr(0, 30).concat('...');
            }

            return url;
        },

        copyAffiliateLink(link) {
            const successMessage = this.$t('publicAffiliate.linkSuccessMessage');
            const errorMessage = this.$t('publicAffiliate.linkCopyError');

            return this.$copyText(link).then(
                () => {
                    this.$toast({
                        message: successMessage,
                    });
                },
                () => {
                    this.$error({
                        message: errorMessage,
                    });
                },
            );
        },

        openLinkUrl(linkUrl) {
            window.open(linkUrl);
        },
    },
};
</script>

<style lang="scss" scoped>
.link-title {
    margin-bottom: 0;
    color: $color-gray-700;
}

.affiliate-link-row {
    padding-top: $spacing-200;
    padding-left: $spacing-400;
}

.action-section {
    margin-top: $spacing-200;
}

.no-affiliate-link-record {
    padding: $spacing-500 $spacing-200;
}
</style>
