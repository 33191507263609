<script>
import { h } from 'vue';

export default {
    props: {
        root: HTMLElement,
        threshold: {
            type: Number,
            default: 1200,
        },
    },

    emits: ['intersect'],

    mounted() {
        const { threshold } = this;

        const options = {
            root: this.root,
            rootMargin: `0px 0px ${threshold}px 0px`,
        };

        const callback = (entries) => {
            const entry = entries[0];

            if (entry.isIntersecting) {
                this.$emit('intersect');
            }
        };

        this.intersectionObserver = new IntersectionObserver(callback, options);
        this.intersectionObserver.observe(this.$el);
    },

    beforeUnmount() {
        this.intersectionObserver.unobserve(this.$el);
    },

    render() {
        return h('div');
    },
};
</script>
