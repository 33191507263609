<template>
    <div class="affiliate-commission-list-section">
        <div v-for="commission in commissionData" :key="commission.name" class="affiliate-commission-row">
            <p class="commission-title">
                {{ $t('publicAffiliate.commissionProgram') }}
            </p>

            <DsListItem
                :description="commission.notes"
                :title="commission.name"
            />
        </div>

        <div v-if="commissionData.length === 0" class="no-commission-program-record" data-qa="no-commissions">
            {{ $t('publicAffiliate.noCommissionAssigned') }}
        </div>
    </div>
</template>

<script>
import { DsListItem } from '@infusionsoft/design-system';

export default {
    components: {
        DsListItem,
    },

    props: {
        commissionData: {
            type: Array,
            default: () => ([]),
        },
    },
};
</script>

<style lang="scss" scoped>
.commission-title {
    margin-bottom: 0;
    color: $color-gray-700;
}

.affiliate-commission-row {
    padding-top: $spacing-200;
    padding-left: $spacing-400;
}

.no-commission-program-record {
    padding: $spacing-500 $spacing-200;
}
</style>
