<template>
    <div class="affiliate-info-view">
        <div v-for="info in affiliateFormData" :key="info.label" class="info-section">
            <p data-qa="info-header">
                {{ info.label }}
            </p>

            <h6> {{ info.value }} </h6>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        affiliateInfo: {
            type: Object,
            default: () => ({}),
        },
    },

    computed: {
        affiliateFormData() {
            if (Object.keys(this.affiliateInfo).length === 0) {
                return [];
            }
            const formattedData = [{
                label: this.$t('publicAffiliate.affiliateName'),
                value: this.affiliateInfo.name,
            },
            {
                label: this.$t('publicAffiliate.affiliateCode'),
                value: this.affiliateInfo.code,
            },
            {
                label: this.$t('publicAffiliate.status'),
                value: this.affiliateInfo.status,
            }];

            if (this.affiliateInfo.affiliate_company_name !== '') {
                formattedData.push({
                    label: this.$t('publicAffiliate.referringCompany'),
                    value: this.affiliateInfo.affiliate_company_name,
                });
            }

            return formattedData;
        },
    },
};
</script>

<style lang="scss" scoped>
.affiliate-info-view {
    padding: $spacing-100 $spacing-300;
}

.info-section {
    padding-bottom: $spacing-300;
}

.info-section p {
    color: $color-gray-700;
    margin-bottom: 0;
}
</style>
