import moment from 'moment';
import defaultMessages from './en-us.json';
import { sharedUiMessages } from '@keap-web/shared-ui';

import {
    createI18n,
    getSupportedLocale,
    loadLocale as loadi18nLocale,
} from '@keap-web/keap-i18n';

const i18n = createI18n({
    messages: {
        'en-us': {
            ...defaultMessages,
            ...sharedUiMessages,
        },
    },
});

const loadLocale = async () => {
    const locale = getSupportedLocale(navigator.language.toLowerCase());

    if (locale !== 'en-us') {
        const { rtl } = await loadi18nLocale(locale);

        i18n.global.locale = locale;
        moment.locale(locale);

        if (rtl) {
            document.documentElement.setAttribute('dir', 'rtl');
        }
    }

    return locale;
};

export { i18n, loadLocale };
