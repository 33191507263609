import { createRouter as createVueRouter, createWebHistory } from 'vue-router';

import sentry from '../analytics/sentry';
import { loadLocale } from '../i18n';
import AffiliatePortal from '@/pages/AffiliatePortal.vue';

const routes = [{
    path: '',
    name: 'home',
    component: AffiliatePortal,
}];

export const createRouter = () => {
    const router = createVueRouter({
        history: createWebHistory(),
        routes,
    });

    router.beforeEach(async () => {
        try {
            await loadLocale();
        } catch (e) {
            sentry.captureException(e);
        }
    });

    return router;
};
