<template>
    <div class="affiliate-portal-page">
        <div class="affiliate-portal-header">
            <h2>{{ $t('publicAffiliate.affiliatePortal') }}</h2>

            <div class="header-logo">
                <img :src="imgUrl" alt="" />
            </div>
        </div>

        <div v-if="error" class="error-section">
            <DsInlineAlert leading-icon type="critical" data-qa="error-banner">
                {{ errorText }}
            </DsInlineAlert>
        </div>

        <div class="affiliate-portal-sections">
            <div class="affiliate-portal-left-section">
                <div class="affiliate-info-section">
                    <h4>{{ $t('publicAffiliate.affiliateInfo') }}</h4>

                    <AffiliateInfo
                        :affiliate-info="affiliateInfo"
                    />
                </div>

                <div class="affiliate-links-section">
                    <h4>{{ $t('publicAffiliate.myAffiliateLinks') }}</h4>

                    <AffiliateLinksList
                        :affiliate-links-info="affiliateLinks"
                    />
                </div>

                <div class="affiliate-commission-section">
                    <h4>{{ $t('publicAffiliate.myCommissionPrograms') }}</h4>

                    <AffiliateCommissionList
                        :commission-data="affiliateCommissions"
                    />
                </div>
            </div>

            <div class="affiliate-portal-right-section">
                <div class="affiliate-commission-total">
                    <h4>{{ $t('publicAffiliate.commission') }}</h4>
                    <PortalLedger />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { DsInlineAlert } from '@infusionsoft/design-system';
import AffiliateInfo from '@/components/AffiliateInfo.vue';
import AffiliateLinksList from '@/components/AffiliateLinksList.vue';
import AffiliateCommissionList from '@/components/AffiliateCommissionList.vue';
import PortalLedger from '@/components/PortalLedger.vue';
import axios from 'axios';


export default {
    components: {
        DsInlineAlert,
        AffiliateInfo,
        AffiliateLinksList,
        AffiliateCommissionList,
        PortalLedger,
    },

    data() {
        return {
            imgUrl: '',
            commissionTotal: '',
            error: false,
            errorText: '',
            affiliateInfo: {},
            affiliateLinks: [],
            affiliateCommissions: [],
        };
    },

    created() {
        this.getPortalBySiteId();
    },

    methods: {
        async getPortalBySiteId() {
            try {
                const urlParams = new URLSearchParams(window.location.search);

                const appId = urlParams.get('app_Id');
                const siteId = urlParams.get('siteId');

                const baseUrl = window.__KEAP__.VUE_APP_CORE_URL_PATTERN?.replace('{id}', appId);

                const { data } = await this.getPortalInfoBySiteId(baseUrl, siteId);

                this.affiliateInfo = data.affiliate;
                this.affiliateLinks = data.affiliate_links;
                this.affiliateCommissions = data.affiliate_programs;
                this.commissionTotal = data.affiliate_commission.affiliateCommissionTotal;
                this.imgUrl = data.company_info.company_logo;
            } catch (e) {
                this.error = true;
                this.errorText = this.$t('publicAffiliate.portalError');
            }
        },

        async getPortalInfoBySiteId(baseUrl, siteId) {
            return axios.get(`${baseUrl}/app/public/spa/v2/affiliates/portal/${siteId}`);
        },
    },
};
</script>

<style lang="scss" scoped>
.affiliate-portal-page {
    max-width: 70rem;
    margin: auto;
}

.affiliate-portal-header {
    display: flex;
    justify-content: space-between;
    border-bottom: solid px-to-rem(1px) $color-gray-200;
    padding-bottom: $spacing-200;
    h2 {
        padding-top: $spacing-300;
    }
}

.affiliate-portal-sections {
    width: 100%;
    display: flex;
}

.header-logo img{
    height: 4rem;
    margin-top: $spacing-200;
}

.error-section {
    text-align: center;
}

.affiliate-portal-left-section {
    width: 25.5rem;
    padding: $spacing-300 0;
    padding-right: $spacing-600;
    h4,.affiliate-links-section {
        padding-bottom: $spacing-200;
    }
}

.affiliate-portal-right-section {
    width: 44.5rem;
    padding: $spacing-300 $spacing-100;
}
</style>
