import * as Sentry from '@sentry/vue';
import { fullStoryIntegration } from '@sentry/fullstory';
import { FullStory } from '@fullstory/browser';

export default {
    init({ app, router }) {
        try {
            Sentry.init({
                app,
                dsn: import.meta.env.VUE_APP_SENTRY_KEY,
                environment: window.__KEAP__.VUE_APP_ENV,
                tracesSampleRate: 0.05,
                ignoreErrors: [
                    /^Request failed with status code \d{3}$/,
                    /Object Not Found Matching Id:\d+/,
                    'Network error: Failed to fetch',
                    'ChunkLoadError',
                    'JWT is expired',
                    'JWT is not present in the auth session',
                    'Redirected when going from', // https://github.com/vuejs/vue-router/blob/v3.3.4/src/history/errors.js#L13
                    'Avoided redundant navigation', // https://github.com/vuejs/vue-router/blob/v3.3.4/src/history/errors.js#L24
                    'Navigation cancelled', // https://github.com/vuejs/vue-router/blob/v3.3.4/src/history/errors.js#L33
                    'Navigation aborted', // https://github.com/vuejs/vue-router/blob/v3.3.4/src/history/errors.js#L44
                ],
                integrations: [
                    fullStoryIntegration(import.meta.env.VUE_APP_SENTRY_ORG, { client: FullStory }),
                    Sentry.browserTracingIntegration({ router }),
                ],
                release: import.meta.env.SENTRY_RELEASE,
                denyUrls: [
                    // Ignore FullStory errors
                    /rs\.fullstory\.com/i,
                ],
            });
        } catch (e) {
            // swallow sentry failures
        }
    },
};
