import { createApp } from 'vue';

import { createDesignSystem, components } from '@infusionsoft/design-system';

import App from './App.vue';
import { createRouter } from './router';
import { i18n } from './i18n';
import { createFullStory } from '@keap-web/analytics-lib/fullstory';
import sentry from '@/analytics/sentry';
import './styles/main.scss';
import VueClipboard from 'vue-clipboard2';


const app = createApp(App);
const router = createRouter();
const designSystem = createDesignSystem({ components });

app.use(designSystem);
app.use(router);
app.use(i18n);
app.use(VueClipboard);

const fullstory = createFullStory({
    orgId: window.__KEAP__.VUE_APP_FULLSTORY_ORG,
    devMode: import.meta.env.DEV,
    enabled: import.meta.env.VUE_APP_THIRD_PARTY_LOGGING === 'enabled',
});

app.use(fullstory);

if (process.env.NODE_ENV !== 'development') {
    sentry.init({ app, router });
}

app.mount('#app');
app.config.globalProperties.$number = i18n.global.n;
